import React from "react";
import { Link } from "react-router-dom";
import Nav from '../Nav';
import './style.css';
import { useTranslation } from 'react-i18next';

function Topbar() {
  const { t, i18n } = useTranslation();
  return (
    <div className="topBar">
      <div className="topbarContainer">
        <div className="topbarContent">
          <div className="logo">
            {t('App name')}
          </div>
          <Nav />
        </div>
      </div>
    </div>
  );
}

export default Topbar;
