import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "language": "English",
      "languageCode": "ENG",
      "App name": "Universal Pain \nTranslator",
      "Describe your pain": "Describe your pain",
      "Close": "Close",
      "Aching": "Aching",
      "Burning": "Burning",
      "Cramping": "Cramping",
      "Crushing": "Crushing",
      "Dull": "Dull",
      "Gnawing": "Gnawing",
      "Heavy": "Heavy",
      "Itchy": "Itchy",
      "Piercing": "Piercing",
      "Pinching": "Pinching",
      "Pressing": "Pressing",
      "Pulsing": "Pulsing",
      "Scalding": "Scalding",
      "Searing": "Searing",
      "Shooting": "Shooting",
      "Squeezing": "Squeezing",
      "Stabbing": "Stabbing",
      "Stretching": "Stretching",
      "Tearing": "Tearing",
      "Throbbing": "Throbbing",
      "Tingling": "Tingling",
    }
  },
  es: {
    translation: {
      "language": "Español",
      "languageCode": "SPA",
      "App name": "Traductor universal \ndel dolor",
      "Describe your pain": "Describa su dolor",
      "Close": "Cerrar",
      "Aching": "Intenso",
      "Burning": "Ardiente",
      "Cramping": "De calambres",
      "Crushing": "Opresivo",
      "Dull": "Sordo",
      "Gnawing": "Constante",
      "Heavy": "Fuerte",
      "Itchy": "Con picazón",
      "Piercing": "Punzante",
      "Pinching": "Como pellizcos",
      "Pressing": "Acuciante",
      "Pulsing": "Pulsante",
      "Scalding": "Urente",
      "Searing": "Lacerante",
      "Shooting": "Fulgurante",
      "Squeezing": "Estrujante",
      "Stabbing": "Incisivo",
      "Stretching": "De estiramiento",
      "Tearing": "Desgarrador",
      "Throbbing": "Pulsátil",
      "Tingling": "Hormigueante",
    }
  },
  uk: {
    translation: {
      "language": "українська",
      "languageCode": "UKR",
      "App name": "Універсальний \nперекладач болю",
      "Describe your pain": "Опишіть свій біль",
      "Close": "закрити",
      "Aching": "Ниючий",
      "Burning": "Пекучий",
      "Cramping": "Спастичний",
      "Crushing": "Стискаючий",
      "Dull": "Тупий",
      "Gnawing": "Постійний",
      "Heavy": "Важкий",
      "Itchy": "Сверблячий",
      "Piercing": "Колючий",
      "Pinching": "Щемливий",
      "Pressing": "Давлячий",
      "Pulsing": "Пульсуючий",
      "Scalding": "Пекучий",
      "Searing": "Роздираючий",
      "Shooting": "Стріляючий",
      "Squeezing": "Стискаючий",
      "Stabbing": "Різкий",
      "Stretching": "Тягнучий",
      "Tearing": "Розриваючий ",
      "Throbbing": "Пульсуючий",
      "Tingling": "Сверблячий",
    }
  },
  zh: {
    translation: {
      "language": "中文",
      "languageCode": "ZHO",
      "App name": "通用疼痛翻译器",
      "Describe your pain": "描述你的痛苦",
      "Close": "关闭",
      "Aching": "疼痛",
      "Burning": "灼痛",
      "Cramping": "绞痛",
      "Crushing": "压迫痛",
      "Dull": "钝痛",
      "Gnawing": "咬痛",
      "Heavy": "重痛",
      "Itchy": "痒痛",
      "Piercing": "刺痛",
      "Pinching": "夹痛",
      "Pressing": "压痛 ",
      "Pulsing": "抽痛",
      "Scalding": "灼痛",
      "Searing": "灼痛",
      "Shooting": "刺痛、剧痛",
      "Squeezing": "压挤痛",
      "Stabbing": "刺痛",
      "Stretching": "拉痛",
      "Tearing": "裂痛",
      "Throbbing": "抽痛",
      "Tingling": "刺痛",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;