import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Home from './views/Home';
import Diagnose from './views/Diagnose';
import Summary from './views/Summary';
import NoPage from './views/NoPage';
//import Nav from './components/Nav';
import Topbar from './components/Topbar';
import Container from './components/Container';
import Footer from './components/Footer';
import ReactGA from 'react-ga';
ReactGA.initialize('G-HHGTKTZT2F');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      {/* <Nav/> */}
      <Topbar />
      <Container>
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path="diagnose" element={<Diagnose />} />
          <Route path="summary" element={<Summary />} /> */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Container>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
