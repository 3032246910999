import { React, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Modal from '../../components/Modal'
import './home.css';
  
function Home (){
    const { t, i18n } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [action, setAction] = useState();
    const timerRef = useRef();
    const isLongPress = useRef();

    const toggleModal = (item, itemEn, lottie) => {
        setModalOpen(!modalOpen);
        if (modalOpen === false){
            setModalData([item, itemEn, lottie]);
        }
    }

    //could set the modal content as a state prop.

    const data = {
        pain:[
            {
                type: `${t('Cramping')}`,
                typeEN: `${t('Cramping', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/01_Cramping.json'
            },
            {
                type: `${t('Tearing')}`,
                typeEN: `${t('Tearing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/02_Tearing.json'
            },
            {
                type: `${t('Burning')}`,
                typeEN: `${t('Burning', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/03_Burning.json'
            },
            {
                type: `${t('Searing')}`,
                typeEN: `${t('Searing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/04_Searing.json'
            },
            {
                type: `${t('Scalding')}`,
                typeEN: `${t('Scalding', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/05_Scalding.json'
            },
            {
                type: `${t('Crushing')}`,
                typeEN: `${t('Crushing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/06_Crushing.json'
            },
            {
                type: `${t('Heavy')}`,
                typeEN: `${t('Heavy', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/07_Heavy.json'
            },
            {
                type: `${t('Pressing')}`,
                typeEN: `${t('Pressing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/08_Pressing.json'
            },
            {
                type: `${t('Pulsing')}`,
                typeEN: `${t('Pulsing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/09_Pulsing.json'
            },
            {
                type: `${t('Throbbing')}`,
                typeEN: `${t('Throbbing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/10_Throbbing.json'
            },
            {
                type: `${t('Dull')}`,
                typeEN: `${t('Dull', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/11_Dull.json'
            },
            {
                type: `${t('Pinching')}`,
                typeEN: `${t('Pinching', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/12_Pinching.json'
            },
            {
                type: `${t('Stretching')}`,
                typeEN: `${t('Stretching', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/13_Stretching.json'
            },
            {
                type: `${t('Aching')}`,
                typeEN: `${t('Aching', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/14_Aching.json'
            },
            {
                type: `${t('Gnawing')}`,
                typeEN: `${t('Gnawing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/15_Gnawing.json'
            },
            {
                type: `${t('Itchy')}`,
                typeEN: `${t('Itchy', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/16_Itching.json'
            },
            {
                type: `${t('Piercing')}`,
                typeEN: `${t('Piercing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/17_Piercing.json'
            },
            {
                type: `${t('Shooting')}`,
                typeEN: `${t('Shooting', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/18_Shooting.json'
            },
            {
                type: `${t('Squeezing')}`,
                typeEN: `${t('Squeezing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/19_Squeezing.json'
            },
            {
                type: `${t('Stabbing')}`,
                typeEN: `${t('Stabbing', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/20_Stabbing.json'
            },
            {
                type: `${t('Tingling')}`,
                typeEN: `${t('Tingling', {lng: 'en'})}`,
                gif: 'URL',
                lottie: process.env.PUBLIC_URL+'/lottie/21_Tingling.json'
            }
        ]
    };
    //console.log(data.pain.length);

    //long press testing
    function startPressTimer() {
        isLongPress.current = false;
        timerRef.current = setTimeout(() => {
            isLongPress.current = true;
            setAction('longpress');
        }, 500)
    }
    function handleOnClick(item, itemEn, lottie) {
        console.log('handleOnClick');
        if (isLongPress.current) {
            console.log('LONG PRESS - not continuing');
            toggleModal(item, itemEn, lottie);
            return;
        }
        setAction('click');
        console.log('CLICK');

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // true for mobile device
            console.log("mobile device");
        } else {
            // false for not mobile device
            console.log("not mobile device");
            toggleModal(item, itemEn, lottie);
        }
    }
    function handleOnMouseDown() {
        console.log('handleOnMouseDown');
        startPressTimer();
    }
    function handleOnMouseUp(item, itemEn, lottie) {
        console.log('handleOnMouseUp');
        handleOnClick(item, itemEn, lottie);
        clearTimeout(timerRef.current);
    }
    function handleOnTouchStart() {
        console.log('handleOnTouchStart');
        startPressTimer();
    }
    function handleOnTouchEnd(item, itemEn, lottie) {
        console.log('handleOnTouchEnd');
        handleOnClick(item, itemEn, lottie);
        clearTimeout(timerRef.current);
    }

    const painItems = data.pain.map((type, index) => (
        <div
            key={index}
            className="col-xs-6 col-sm-4 col-md-3 col-lg-3"
            id={index.toString()}
        >
            <div className="painItem" 
                onClick={() => toggleModal(data.pain[index].type, data.pain[index].typeEN, data.pain[index].lottie)}
                //onClick={() => handleOnClick(data.pain[index].type, data.pain[index].typeEN, data.pain[index].lottie)}
                //onMouseDown={handleOnMouseDown}
                //onMouseUp={() => handleOnMouseUp(data.pain[index].type, data.pain[index].typeEN, data.pain[index].lottie)}
                //onTouchStart={handleOnTouchStart}
                //onTouchEnd={() => handleOnTouchEnd(data.pain[index].type, data.pain[index].typeEN, data.pain[index].lottie)}
            >
                <div className="lottieAnimation">
                    <Player
                        //autoplay
                        lottieRef={instance => {
                            //this.setState({ lottie: instance }); // the lottie instance is returned in the argument of this prop. set it to your local state
                            console.log({lottie: instance});
                        }}
                        loop
                        hover
                        src={data.pain[index].lottie}
                    >
                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player>
                </div>
                <div className="painType">{data.pain[index].type}</div>
                {i18n.language !== 'en' ? <div className="painTypeEN">{data.pain[index].typeEN}</div> : null}
            </div>
        </div>
    ));
    return (
        <>
            <h1>{t('Describe your pain')}</h1>
            <div className="row">{painItems}</div>
            <Modal isOpen={modalOpen} data={modalData} toggleModal={toggleModal} />
        </>
    );
}
  
export default Home;