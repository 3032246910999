import React from "react";
import { Link } from "react-router-dom";
import './style.css';

function Footer() {
  return (
    <footer>
      <div className="footerContainer">
        <div className="footerContent">
          <div>&copy;Wongdoody 2023</div>
          <div>{/* links */}</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;