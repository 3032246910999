import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ModalNav from '../ModalNav';
import './style.css';


function Modal({ isOpen, data, toggleModal}) {
    const { t, i18n } = useTranslation();
    console.log(isOpen);
  return (
    <>
        <div className="modal">
          <div className="modalBackground">
            <div className="modalContainer">

              <div className="modalNav">
                <div className="modalNavContent">
                  <ModalNav />
                </div>
              </div>

              <div className="modalContent">

                <div className="lottieAnimationModal">
                  <Player
                    autoplay
                    loop
                    //hover
                    src={data[2]}
                  >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                  </Player>
                </div>

                {/* <div className="modalTitle">{t(data[0])}</div> */}
                <div className="modalTitle">{t(data[1])}</div>
                {i18n.language !== 'en' ? <div className="modalTitleEN">{data[1]}</div> : null}
                <div className="modalButton">
                  {/* <button onClick={toggleModal}>{t('Close')}</button> */}
                  <div className="btn" role="button" onClick={toggleModal}>{t('Close')}</div>
                </div>

              </div>
            </div>

          </div>
        </div>
    

        <style jsx>{`
          .modal {
            display: ${isOpen ? 'fixed' : 'none'};
            position: fixed;
            z-index: 20;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
          }
          .modalBackground {
            background: rgba(0,0,0,0.5);
            width:100%;
            height:100%;
            display:flex;
            justify-content: center;
            align-items: center;
          }
          .modalContainer {
            /* background: white; */
            width: 100%;
            /* max-width: 1400px; */
            height: 100%;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
            display:flex;
            justify-content: center;
            align-items: center;
            position: relative;

            background: linear-gradient(9.43deg, #609FFF -44.54%, #FFFFFF 40.66%);
            box-shadow: 81px 155px 70px rgba(0, 0, 0, 0.01), 45px 87px 59px rgba(0, 0, 0, 0.04), 20px 39px 44px rgba(0, 0, 0, 0.07), 5px 10px 24px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);
          }
          .modalContent {
            padding: 40px;
            display: flex;
            flex-direction: column;
            text-align: center;
          }

          .modalNav {
            position: absolute;
            top:0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
          .modalNavContent {
            padding: 24px 20px;
          }


          .lottieAnimationModal {
            /* border: 1px solid red; */
            width: 100%;
            max-width: 400px;
            margin-bottom: 40px;
          }
          .modalTitle {
            font-weight: 700;
            font-size: 56px;
            line-height: 66px;
          }
          .modalTitleEN {
            font-weight: 700;
            font-size: 56px;
            line-height: 66px;
            color: rgba(0,0,0,0.5);
            margin-top:10px;
          }
          .modalButton {
            margin-top: 80px;
          }
          .modalButton button {
            padding: 15px 40px;
          }
          .modalButton .btn {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            text-decoration: underline;
            text-underline-offset: 5px;
            cursor: pointer;
            padding: 20px;
          }

          

          @media screen and (max-width: 440px) {
            .modalTitle {
              font-size: 40px;
            }
            .modalTitleEN {
              font-size: 40px;
            }
          }
          @media screen and (max-width: 375px) {
            .modalTitle {
              font-size: 32px;
            }
            .modalTitleEN {
              line-height: normal;
              margin-top: 0px;
              font-size: 32px;
            }
            .modalButton {
              margin-top: 20px;
            }
          }
          @media screen and (max-width: 767px) {
          }
          @media screen and (min-width: 768px) {
          }
          @media screen and (min-width: 1024px) {
          }
          @media screen and (min-width: 1200px) {
          }
      `}</style>
    </>
  );
}

export default Modal;
