import React from "react";
import { Link } from "react-router-dom";
import './style.css';

function Container(props) {
  return (
    <div className="container">
      <div className="content">
        {props.children}
      </div>
    </div>
  );
}

export default Container;