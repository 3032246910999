import {React, useState} from "react";
import { Link } from "react-router-dom";
import Hamburger from "../Hamburger/Hamburger";
import { useTranslation } from 'react-i18next';
import { BsArrowLeftRight, BsXLg } from "react-icons/bs";
//import './style.css';

function Nav() {
  const { t, i18n } = useTranslation();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  console.log(i18n.language);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  }

  const setLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <>
      <div className="navigation">
        <ul>
          <li><div className="close" onClick={toggleHamburger}><BsXLg/></div></li>
          <li>
            <div className={i18n.language === 'en' ? "btn active" : "btn"} role="button" onClick={() => setLanguage('en')}>
              <div>{t('language', {lng: 'en'})}</div>
              {/* {i18n.language !== 'en' ? <div><BsArrowLeftRight/></div> : null}
              {i18n.language !== 'en' ? <div>{t('language', {lng: i18n.language})}</div> : null} */}
            </div>
          </li>
          <li>
            <div className={i18n.language === 'es' ? "btn active" : "btn"} role="button" onClick={() => setLanguage('es')}>
              <div>{t('language', {lng: 'es'})}</div>
              {/* {i18n.language !== 'es' ? <div><BsArrowLeftRight/></div> : null}
              {i18n.language !== 'es' ? <div>{t('language', {lng: i18n.language})}</div> : null} */}
              <div><BsArrowLeftRight/></div>
              <div>{t('language', {lng: 'en'})}</div>
            </div>
          </li>
          <li>
            <div className={i18n.language === 'uk' ? "btn active" : "btn"} role="button" onClick={() => setLanguage('uk')}>
              <div>{t('language', {lng: 'uk'})}</div>
              {/* {i18n.language !== 'uk' ? <div><BsArrowLeftRight/></div> : null}
              {i18n.language !== 'uk' ? <div>{t('language', {lng: i18n.language})}</div> : null} */}
              <div><BsArrowLeftRight/></div>
              <div>{t('language', {lng: 'en'})}</div>
            </div>
          </li>
          <li>
            <div className={i18n.language === 'zh' ? "btn active" : "btn"} role="button" onClick={() => setLanguage('zh')}>
              <div>{t('language', {lng: 'zh'})}</div>
              {/* {i18n.language !== 'zh' ? <div><BsArrowLeftRight/></div> : null}
              {i18n.language !== 'zh' ? <div>{t('language', {lng: i18n.language})}</div> : null} */}
              <div><BsArrowLeftRight/></div>
              <div>{t('language', {lng: 'en'})}</div>
            </div>
          </li>
        </ul>
        <div className="hamburger" style={{flexDirection:'row', alignItems:'center', fontSize:'18px', fontWeight:'700', cursor:'pointer', justifyContent:'end', width:'auto',height:'20px'}} onClick={toggleHamburger}>
          <div style={{width:'auto'}}>{t('language')}</div>&nbsp;<Hamburger isOpen={hamburgerOpen} />
        </div>
      </div>
        
      <style jsx>{`
        .navigation {
          display: flex;
          align-items: center;
          z-index: 6;
          position: relative;

          /* min-width: 285px; */
          justify-content: ${hamburgerOpen ? 'space-between' : 'end'};
        }
       

        .navigation .close {
          z-index: 9;
          display: ${hamburgerOpen ? 'flex' : 'none'};
          font-size:1.25em;
          cursor: pointer;
          position: absolute;
          top: 25px;
        }
        
        .navigation ul {
          display: ${hamburgerOpen ? 'inline' : 'none'};
          background-color: white;
          height: 100vh;
          /* width: 200px; */
          min-width: 320px;
          width: 100%;
          max-width: 320px;
          position: absolute;
          margin-top: -20px;
          margin-right: -20px;
          right:0;
          top:-6px;
          padding: 100px 0px 0px 0px;
          background: linear-gradient(9.43deg, #609FFF -44.54%, #FFFFFF 40.66%);
          box-shadow: 81px 155px 70px rgba(0, 0, 0, 0.01), 45px 87px 59px rgba(0, 0, 0, 0.04), 20px 39px 44px rgba(0, 0, 0, 0.07), 5px 10px 24px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);
        }
        
        .navigation ul li{
          list-style-type: none;
          padding: 5px 10px;
        }

        .navigation ul li button {
          width: 100%;
        }

        .navigation ul li .btn {
          padding: 15px;
          /* background: #F7F7F7;
          border: 1px solid #999; */
          border-radius: 5px;
          font-weight: 400;
          font-size: 22px;
          cursor: pointer;
          text-align: center;
          color:rgba(74, 74, 74, 0.7);
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 20px;
        }
        .navigation ul li .btn.active {
          background: #DAE4EC;
          border: 1px solid #A0C1DD;
          font-weight:bold;
          color:rgba(4, 15, 46, 1);
        }

        .hamburger{
          display:flex;
          z-index: 6;
          width: auto;
        }
        
      `}</style>
    </>
  );
}

export default Nav;
